<!-- <template>
	<div class="card card-custom card-stretch gutter-b bg-danger">
		<div class="card-header border-0">
			<h3 class="card-title font-weight-bolder text-dark">TODO: {{props }}</h3>
		</div>
		<div class="card-body pt-2">
			{{props}}
		</div>
	</div>
</template> -->

<template>
	<div class="card card-custom card-stretch gutter-b">
		<div class="card-header border-0 align-items-center">
            <h3 class="card-title font-weight-bolder text-dark">
                {{ device.properties && device.properties.label ? device.properties.label : name}} - Newtec {{ graphName }}
				<!-- <a
					v-if="['admin', 'noc', 'staff'].includes(currentUser.role)"
					target="_blank"
					:href="`https://orionweb2017.itcglobal.com/Orion/Interfaces/InterfaceDetails.aspx?NetObject=I:${interfaceID}&view=InterfaceDetails`"
					v-b-tooltip="'Open in Solarwinds'"
				>
					<img class="mx-5" width="20" :src="'/media/logos/solarwinds.png'" />
				</a> -->
                <!-- <b-button v-on:click="getStarlinkMetrics">TEST</b-button> -->
			</h3>
			<!-- <div>
				<b-button v-if="showDownload" variant="outline-primary" size="sm" @click="downloadTotalBW"
					>Download CSV</b-button
				>
			</div> -->
		</div>
		<div class="card-body pt-2" style="position: relative;">
            <div v-if="!loaded" class="d-flex justify-content-center" style="align-items:center; position: absolute;
                top: -10%;
                left: 0;
                width: 100%;
                height: 100%;"> 
                <b-spinner class="m-5" label="Busy"></b-spinner>
            </div>
			<div v-if="loaded === true && dyData.length === 0">
				<h4>
					<i
						class="fa fa-exclamation-circle fa-circle"
						aria-hidden="true"
						style="
							color: red;
							background-image: radial-gradient(at center, white 40%, transparent 40%);
						"
					></i>
					This Newtec graph has no data available at this time.
				</h4>
			</div>
			<dygraphs v-if="dyData.length > 0" :data="dyData" :options="dyOpts" />
		</div>
	</div>
</template>

<script>
// import { beforeRouteLeave } from 'vue-router';
import { downloadCSV } from '@/helpers';
const date = new Date();
const offset = date.getTimezoneOffset() * 60000;
import { KMG } from '@/helpers';
import { mapGetters } from 'vuex';
import Dygraph from 'dygraphs';

export default {
	name: 'NewtecGraphs',
    components: {
        dygraphs: () => import('@/view/content/lib/dygraphs.vue'),
    },
	props: {
        siteid: { Type: Number, required: true},
        name: { Type: String, required: true },
		id: { type: Number, required: true },
		properties: { type: Object, require: false },
        device: { type: Object, require: false },
        widgetId: { type: Number, required: true}
	},
	data() {
		return {
            metricParam: 'metric',//single or multiple metrics: backend takes 'metric' vs 'metrics' to determine which api to hit.
            metrics: '',
            metricsCount: 0,
            last_timestamp: '',
            end: '',
            dates: {
                start: '',
                end: ''
            },
            consolidatedData: [],
            newtecMetrics: [],
            errors: [],
            dyData: [],
			dyOpts: {},
			showDownload: false,
			loaded: false,
            graphName: '',
		};
	},
    computed: {
		...mapGetters(['currentUser', 'DateRange']),
	},
    methods: {
        updateNewtecMetricData() {
            this.loaded = false;
            this.$http.get(`site/${this.siteid}/newtec/${this.device.Source}/metric/${this.graphName}?start=${this.last_timestamp}`).then((res) => {
                let newData = false;
                const CountOfMetrics = this.metricsCount;
                if(res.data) {
                    const MetricKeyNames = Object.keys(res.data.series_data);
                    for(let i=0; i<CountOfMetrics; i++) {
                        const metricData = res.data.series_data[MetricKeyNames[i]];
                        const new_data_index = metricData.findIndex(item => item[0] > this.last_timestamp);
                        if(new_data_index !== -1) {
                            const newMetricData = metricData.slice(new_data_index);
                            this.newtecMetrics.series_data[MetricKeyNames[i]] = [...this.newtecMetrics.series_data[MetricKeyNames[i]], ...newMetricData];
                            newData = true;
                        } else {
                            break;
                        }
                    }
                } 
                // else if (res.data && CountOfMetrics<2) {
                //     const metricData = res.data[0].data;
                //     const new_data_index = metricData.findIndex(item => item.timestamp > this.last_timestamp);
                //     if(new_data_index !== -1) {
                //         const newMetricData = metricData.slice(new_data_index);
                //         this.starlinkMetrics[0].data = [...this.starlinkMetrics[0].data, ...newMetricData];
                //         newData = true;
                //     } 
                // }
                if(newData === true) {
                    this.processAPIData(CountOfMetrics);
                    this.setLastTimeStamp(res.data);
                } else {
                    this.loaded = true;
                }
            })
            .catch(e => {
                this.loaded = true;
                this.errors.push(e);
                console.log("Error(Updating newtec Data): ", this.errors);
            })
        },

        getNewtecData(initialLoad=null) {
            this.loaded = false;
            // this.$http.get(`site/${this.siteid}/newtec/metrics/${this.device.Source.kitSerialNumber}?${this.metricParam}=${this.metrics}&start=${this.last_timestamp}&end=${this.end}`).then((res) => {
            this.$http.get(`site/${this.siteid}/newtec/${this.device.Source}/metric/${this.graphName}?start=${this.DateRange.start}&end=${this.DateRange.end}`).then((res) => {
                this.stopTimer();
                this.newtecMetrics = res.data;
                this.setLastTimeStamp(res.data)
                this.updateGraphData(); //start auto-update timer
                this.metricsCount = Object.keys(res.data.series_data).length;
                this.processAPIData(this.metricsCount); 
                this.loaded=true;
            })
            .catch(err => {
                this.loaded = true;
                this.errors.push(err);
                console.log("Error(NEWTEC API Data): ", this.errors);                                                                            
            })
        },
        setLastTimeStamp(resData) {
            const metricName = Object.keys(resData.series_data)[0];
            const seriesLengths = resData.series_lengths[metricName];
            if(seriesLengths>0) {
                const lastIndex = seriesLengths-1;
                const lastDataPoint = resData.series_data[metricName][lastIndex];
                this.last_timestamp = lastDataPoint[0];
            }
        },
        saveToStorage(res) {
            if(!this.device.Source.kitSerialNumber) {
                const starlink = JSON.parse(this.device.Source);
                this.device.Source = starlink; 
            }
            localStorage.setItem(`starlinkMetrics_${this.widgetId}_${this.device.Source.kitSerialNumber}`, JSON.stringify(res))
        },
        updateGraphData() {
            this.graphDataTimer = setInterval(function () {
                this.updateNewtecMetricData();
            }.bind(this), 300000); 
        },
        stopTimer(){
            clearInterval(this.graphDataTimer);
        },
        setOptions() {
            const caseOptions = this.setDygraphOptions();

            this.dyOpts = {
                labels: caseOptions.labels,
                labelsKMG2: false,
                labelsSeparateLines: false,
                legend: 'always',
                fillGraph:
                    this.properties?.showmingraph || this.properties?.showmaxgraph ? false : true,
                strokeWidth: 1.5,
                fillAlpha: 0.06,
                axes: caseOptions.axes,
                series: caseOptions.series,
                includeZero: true,
                connectSeparatedPoints: true,
            }
        },
        async consolidateData(CountOfMetrics, starlinkData) { //no need to wait on this process
            this.consolidatedData = starlinkData; //creates object keys for below use.
            if(starlinkData[0].data.length > 499) {
                for(let i=0; i < CountOfMetrics; i++) {
                    //consolidate data to 5 minute intervals in the last 24 hours and save to local storage.
                    let allData = starlinkData[i].data; 
                    const dataLength = allData.length;
                    let tempConsArray = [];
                    for(let j=0; j < dataLength; j+=5) {
                        tempConsArray.push(allData[j]); //add every 5th point which is ~5 minute intervals
                    }
                    this.consolidatedData[i].data = tempConsArray.slice(-300);
                    this.saveToStorage(this.consolidatedData);
                }
            } else {
                this.saveToStorage(starlinkData);
            }
        },
        adjustToUTC(date) {
            return new Date(date.getTime() + date.getTimezoneOffset() * 60000);
        },
        processAPIData(metricsCount, storage=null) {
            // if(storage !== 'fromStorage') {
            //     this.consolidateData(metricsCount, this.starlinkMetrics);
            // }
            let metrics = {};
            let formattedData = [];
            let DATA = this.newtecMetrics.series_data;
            const firstSeriesKey = Object.keys(DATA)[0];
            const seriesLength = DATA[firstSeriesKey].length;
            // const startIndex = Math.max(0, seriesLength - 1800);
            const startIndex = 0;
            if(metricsCount>1) {
                const MetricKeyNames = Object.keys(this.newtecMetrics.series_data);
                for(let i=startIndex; i < seriesLength; i++) {
                    let date = new Date(DATA[MetricKeyNames[0]][i][0]);
                    let adjustedDate = this.adjustToUTC(date);
                    let result = [adjustedDate];
                    for(let n = 0; n < MetricKeyNames.length; n++) {
                        if(DATA[MetricKeyNames[n]][i] && DATA[MetricKeyNames[n]][i][1]) {
                            result.push(DATA[MetricKeyNames[n]][i][1])
                        } else {
                            result.push(undefined)
                        }
                    }   
                    formattedData.push(result);
                }
            } else {
                for(let i=startIndex; i < seriesLength; i++) {
                    let date = new Date(metrics.s1[i][0]);
                    let adjustedDate = adjustToUTC(date);
                    formattedData.push([adjustedDate, metrics.s1[i][1]]);
                }
            }
        
            this.dyData = formattedData;
            this.loaded = true;
        },
        setDygraphOptions() {
            switch(this.widgetId) {
                case 60:
                    this.metricParam = 'metrics';
                    this.metrics = ['sent_bytes','rx_bytes'];
                    this.graphName = 'Traffic';
                    let options53 = {
                        labels: ['Timestamp', 'Fwd Throughput', 'Rtn Throughput'],
                        axes: {
                            y: {
                                valueFormatter: function (y) {
                                    return KMG(y) + 'bps';
                                },
                                axisLabelFormatter: function (y) {
                                    return KMG(y);
                                },
                            },
                            y2: {
                                valueFormatter: function (y) {
                                    return KMG(y) + 'bps';
                                },
                                axisLabelFormatter: function (y) {
                                    return KMG(y);
                                },
                            }
                        },
                        series: {
                            Down: {
                                axis: 'y'
                            },
                            Up: {
                                axis: 'y2'
                            }
                        },
                    }
                    return options53
                case 61:
                    // this.metrics = 'pingLatency';
                    this.graphName = 'EsN0';
                    let options54 = {
                        labels: ['Timestamp', 'Fwd EsN0', 'Rtn HRC EsN0'],
                        axes: {
                            y: {
                                valueFormatter: function (v) {
                                        return v + 'dB';
                                },
                                axisLabelFormatter: function (y) {
                                    return y+'dB';
                                },
                            },
                        },
                        // series: {
                        //     'Ping Latency': {
                        //         color: '#54a5ff',
						// 	},
                        // },
                    }
                    return options54
                case 62:
                    // this.metrics = 'pingDropRate';
                    this.graphName = 'Power';
                    let options55 = {
                        labels: ['Timestamp', 'Actual BEPD', 'Tx Power Density', 'Rx Power Density', 'Tx Power'],
                        axes: {
                            y: {
                                valueFormatter: function (v) {
                                    return (v).toFixed(3) + 'dBm/Hz';
                                },
                                axisLabelFormatter: function (v) {
                                    return v + 'dBm/Hz';
                                },
                                axisLabelWidth: 100
                            },
                            y2: {
                                valueFormatter: function (y) {
                                    return (y).toFixed(2) + 'dBm';
                                },
                                axisLabelFormatter: function (y) {
                                    return (y).toFixed(2) + 'dBm';
                                },
                                axisLabelWidth: 100
                            }
                        },
                        series: {
                            'Tx Power Density': {
                                axis: 'y'
                            },
                            'Tx Power': {
                                axis: 'y2'
                            }
                        },
                    }
                    return options55
                case 63:
                    this.metrics = 'signalQuality';
                    this.graphName = 'Signal Quality';
                    let options56 = {
                        labels: ['Timestamp', 'Signal Quality'],
                        axes: {
                            y: {
                                valueFormatter: function (v) {
                                    return v*100 + '%';
                                },
                                axisLabelFormatter: function (y) {
                                    return Math.round(y*100);
                                },
                            },
                        },
                        series: {
							'Signal Quality': {
								color: '#54a5ff',
							},
						},
                    }
                    return options56
                case 64:
                    this.metrics = 'obstruction';
                    this.graphName = 'Obstruction';
                    let options57 = {
                        labels: ['Timestamp', 'Obstruction'],
                        axes: {
                            y: {
                                valueFormatter: function (v) {
                                    return v*100 + '%';
                                },
                                axisLabelFormatter: function (y) {
                                    return Math.round(y*100);
                                },
                            },
                        },
                        series: {
							'Obstruction': {
								color: '#ff0000',
							},
						},
                    }
                    return options57
            }

        },
    },
    watch: {
        DateRange: {
            handler(nVal, oVal) {
                this.getNewtecData();
            },
            deep:true,
        }
	},
    created() {
    // console.log("Created::")
    },
    beforeMount() {
        // console.log("Before MOUNT::")
        this.setOptions();
        this.getNewtecData();
    },
    mounted() {
        // console.log("MOUNTED::")
    },

    beforeUpdate() {
        // console.log("Before Update::")
        // this.getNewtecData();
    },
    updated() {
        // console.log("Updated::")
        // this.getNewtecData();
    },
    unmounted() {
        // console.log("Unmounting::")
    },
    beforeDestroy() {
        // console.log("Before DESTROY::")
        this.stopTimer();
    }


};
</script>

<style>
</style>